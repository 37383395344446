import httpUtil from "@/utils/httpUtil";

/**绑定探索 */
export const exploreAdd =  params => httpUtil.post("/api/crmPc/explore/add", params);

/**更新探索 */
export const exploreUpdate =  params => httpUtil.post("/api/crmPc/explore/update", params);

/**删除探索 */
export const exploreDeleteById =  params => httpUtil.post("/api/crmPc/explore/deleteById", params);

/**探索--访问状态 */
export const exploreSwitchExplore =  params => httpUtil.post("/api/crmPc/explore/switchExplore", params);

/**探索表格 */
export const exploreQueryPage =  params => httpUtil.post("/api/crmPc/explore/queryPage", params);

